<template>
  <v-card color="grey lighten-3" flat>
    <v-tabs
      v-model="activeTab"
      show-arrows
    >
      <v-tabs-slider color="color3"></v-tabs-slider>
      <v-tab href="#info">
        Information
      </v-tab>
      <v-tab href="#settings">
        Privacy
      </v-tab>
      <v-tab href="#vid" v-if="player && player.recruitFields">
        Videos
      </v-tab>
      <v-tab href="#img">
        Images
      </v-tab>
      <v-tab href="#metrics">
        Volleyball
      </v-tab>
      <v-tab href="#academics" v-if="player && player.recruitFields">
        Academics
      </v-tab>
      <v-tab href="#contacts" v-if="player && (player.recruitFields || view.vbl)">
        Contacts
      </v-tab>
    </v-tabs>

    <ntdp-optin
      v-if="player && player.recruitFields && user && user.id === 1 && false"
      :player="player"
    ></ntdp-optin>

    <v-tabs-items v-model="activeTab">
      <v-tab-item value="info">
        <v-card color="grey lighten-3" flat>
          <basic-info
            v-if="player"
            :player="player"
            :active="active"
            :view="view"
          ></basic-info>
        </v-card>
      </v-tab-item>

      <v-tab-item value="vid" v-if="player && player.recruitFields">
        <profile-video-library
          :id="active"
          :player="player"
          :active="activeTab === 'vid'"
        ></profile-video-library>
      </v-tab-item>

      <v-tab-item value="img">
        <profile-image-library
          :player="player"
          :active="activeTab === 'img'"
        ></profile-image-library>
      </v-tab-item>

      <v-tab-item value="metrics">
        <v-card color="grey lighten-3" flat>
          <volleyball-metrics
            :player="player"
            :active="activeTab === 'metrics'"
            :view="view"
          ></volleyball-metrics>
        </v-card>
      </v-tab-item>

      <v-tab-item value="academics" v-if="player && player.recruitFields">
        <v-card color="grey lighten-3" flat>
          <academic-information
            v-if="player"
            :player="player"
            :active="activeTab === 'academics'"
            :view="view"
          ></academic-information>
        </v-card>
      </v-tab-item>

      <v-tab-item value="contacts" v-if="player && (player.recruitFields || view.vbl)">
        <v-card color="grey lighten-3" flat>
          <contacts
            v-if="player"
            :player="player"
            :active="activeTab === 'contacts'"
            :view="view"
          ></contacts>
        </v-card>
      </v-tab-item>

      <v-tab-item value="settings">
        <v-card color="grey lighten-3" flat>
          <settings-form
            v-if="player"
            ref="settings"
            :player="player"
            :active="activeTab === 'settings'"
          ></settings-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicInfo from '@/components/Player/Editor/BasicInfo.vue'
import ProfileImageLibrary from '@/components/Player/ProfileImageLibrary'
import ProfileVideoLibrary from '@/components/Player/ProfileVideoLibrary'
const VolleyballMetrics = () => import('@/components/Player/Editor/VolleyballMetrics.vue')
const AcademicInformation = () => import('@/components/Player/Editor/AcademicInformation.vue')
const Contacts = () => import('@/components/Player/Editor/Contacts.vue')
const SettingsForm = () => import('@/components/Player/Settings/Index')
const NtdpOptin = () => import('@/New/NtdpOptin')

export default {
  props: ['player', 'active', 'view'],
  data () {
    return {
      activeTab: 'info'
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    BasicInfo,
    ProfileImageLibrary,
    ProfileVideoLibrary,
    VolleyballMetrics,
    AcademicInformation,
    Contacts,
    SettingsForm,
    NtdpOptin
  }
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .2;
  position: absolute;
  width: 100%;
}
.v-card--reveal-text {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
  font-size: 2em !important;
}

</style>
