<template>
  <image-library
    ref="library"
    :images="images"
    :loading="loading"
    :deleteing="deleteing"
    :uploading="uploading"
    @delete-image="deleteImage"
    @upload-image="uploadImage"
    @make-profile="makeProfile"
    :profilePic="player.profilePic"
    :profile="true"
  ></image-library>
</template>

<script>
import ImageLibrary from '@/components/Utils/ImageLibrary'
export default {
  props: ['player', 'active'],
  data () {
    return {
      loading: false,
      loaded: false,
      deleteing: false,
      uploading: false,
      images: []
    }
  },
  methods: {
    loadImages () {
      if (this.loaded) return
      this.loading = true
      this.$VBL.player.images.getAll(this.player.id)
        .then(r => {
          this.images = r.data
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => { this.loading = false })
    },
    deleteImage (id) {
      this.deleteing = true
      this.$VBL.player.images.delete(this.player.id, id)
        .then(response => {
          var i = this.images.findIndex(f => f.id === id)
          this.images.splice(i, 1)
          this.$refs.library.dialog = false
        })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.deleteing = false
        })
    },
    uploadImage (unsavedImage) {
      if (this.uploading) return

      this.uploading = true
      this.$VBL.player.images.post(this.player.id, unsavedImage.formData)
        .then(r => {
          this.images.push(...r.data)
          this.$refs.library.uploaded()
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.uploading = false })
    },
    makeProfile (id) {
      this.uploading = true
      this.$VBL.player.images.makeProfile(this.player.id, id)
        .then(r => {
          this.player.pic = r.data
          this.$refs.library.dialog = false
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.uploading = false })
    }
  },
  watch: {
    active: 'loadImages'
  },
  components: {
    ImageLibrary
  },
  mounted () {
    if (this.active) {
      this.loadImages()
    }
  }
}
</script>

<style scoped>

</style>
